html,
body {
  height: 100vh;
  color: transparent;
}

.container {
  background-color: transparent;
}

.navigation {
    position: fixed;
    right: 30px;
    list-style: none;
    top: 50%;
    transform: translateY(-50%);
    z-index: 10;
}

.navigation li {
    width: 7px;
    height: 7px;
    background: #ffffff;
    border: 2px solid #000000;
    border-radius: 50%;
    margin-top: 15px;
    transition: all 0.15s;
}

.navigation li:hover, .navigation li.active {
    transform: scale(1.4);
    border: 2px solid #872feb;
}

.parent {
  overflow: scroll;
  height: 100vh;
  scroll-snap-points-y: repeat(100vh);
  scroll-snap-type: y mandatory;
  scroll-behavior: smooth;
  overflow-x: hidden;
}

section {
  height: 100vh;
  scroll-snap-align: center;
  position: relative;
  scroll-behavior: smooth;
}

.home {
  
  width: auto;
  padding-left: auto;
  background-color: transparent;
  margin: auto;
}

.apresentacao {
  padding-top: 250px;
  font-size: 65px;
  color: white;
  font-family: Montserrat;
  font-weight: 400;
  text-align: center;
}

.nome {
  font-size: 70px;
  font-weight: 600;
}

.subtitle {
  font-size: 30px;
  font-family: Montserrat;
  font-weight: 200;
  color: white;
  text-align: center;
}

.imgsrow {
  display: flex;
  justify-content: center;
  align-items: center;
}


.column {
  float: left;
  padding: 10px;
}

.c {
  width: 45px;
  height: 55px;
}

.git {
  width: 55px;
  height: 50px;
}

.java {
  width: 45px;
  height: 45px;
  border-radius: 8px;
}

.react {
  width: 60px;
  height: 50px;
}

.project {
  background-color: transparent;
}

.project-title-text {
  padding-top: 100px;
  font-size: 65px;
  color: white;
  font-family: Montserrat;
  font-weight: 700;
  text-align: center;
}

.project-content-text {
  font-size: 35px;
  color: white;
  font-family: Montserrat;
  font-weight: 500;
  padding-top: 250px;
  text-align: center;
}

html {
    background-color: #000;
    overflow: hidden;
}

.tv {
    height: '50%';
    width: '50%';
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
}

.text{
    color: #fff;
    font-size: 2em;
    font-weight: bold;
    text-align: center;
    margin-top: 5%;
}

.text2{
    color: #fff;
    font-size: 1em;
    text-align: center;
}

.button{
    background-color: #000;
    color: #fff;
    font-size: 1em;
    text-align: center;
    margin-left: 45%;
    padding: 1%;
    border: none;
    cursor: pointer;
}